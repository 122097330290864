import ROUTE_NAME from '@/router/routeName'

export default {
    metaInfo: () => ({
       title: 'DATAFORCE - Платформа для управления ростом эффективности сайтов онлайн ритейла и маркетплейсов'
    }),

    data: () => ({
        menuMobile: false,

        showMenuMobile: false,

        ROUTE_NAME,

        headerLinks: [
            {
                title: 'Для кого',
                to: {
                    name: ROUTE_NAME.INDEX,
                    hash: '#users'
                }
            },
            {
                title: 'Какие задачи решает',
                to: {
                    name: ROUTE_NAME.INDEX,
                    hash: '#questions'
                }
            },
            {
                title: 'Стоимость',
                to: {
                    name: ROUTE_NAME.INDEX,
                    hash: '#cost'
                }
            },
            {
                title: 'Почему мы',
                to: {
                    name: ROUTE_NAME.INDEX,
                    hash: '#partners-block'
                }
            },
            {
                title: 'Помощь',
                to: {name: ROUTE_NAME.HELP}
            },
            {
                title: 'О нас',
                to: {name: ROUTE_NAME.ABOUT_TEAM}
            },
            {
                title: 'Контакты',
                to: {name: ROUTE_NAME.CONTACTS}
            }
        ]
    }),

    computed: {
        menuBtnClass () {
            let classList = ['header__menu-button']
            if (this.showMenuMobile) {
                classList.push('_close')
            }

            return classList
        },

        headerBlockClass () {
            let classList = ['header__body-block']
            if (this.showMenuMobile) {
                classList.push('_mobile')
            }

            return classList
        },

        headerLinksClass () {
            let classList = ['header__body-links']
            if (this.showMenuMobile) {
                classList.push('_mobile')
            }

            return classList
        }
    },

    created () {
        document.documentElement.style.scrollBehavior = 'smooth'
    },

    mounted() {
        document.addEventListener('keydown', this.closeMenu)
    },

    destroyed () {
        document.documentElement.style = undefined
        document.removeEventListener('keydown', this.closeMenu)
    },

    methods:{
        toggleMenu () {
            this.showMenuMobile ^= true
        },

        closeMenu (e) {
            const escKeyCode = ['Escape', 'Esc']
            if (this.showMenuMobile) {
                if (
                    ('key' in e && escKeyCode.includes(e.key)) ||
                    e.keyCode === 27
                ) {
                    this.showMenuMobile = false
                }
            }
        }
    }
}
